<template>
  <div class="container pt-3">
    <el-page-header @back="$router.go(-1)" :content="title"></el-page-header>
    <div class="box-card mt-3">
      <div class="titleStyle">
        <h1 class="primaryColor" style="font-size: 30px;">
          {{ title }}
        </h1>
        <el-divider></el-divider>
        <el-tabs v-model="activeName" type="border-card">
          <el-tab-pane :label="$t('userProfile.basicInfo')" name="info">
            <Heading :content="$t('userProfile.basic.title')"></Heading>
            <hr />
            <el-form
              v-if="userInfo"
              ref="form"
              :model="userInfo"
              label-position="left"
              label-width="120px"
            >
              <el-form-item :label="'Name'">
                <b class="text-success">{{ title }}</b>
              </el-form-item>
              <el-form-item :label="'Email'">
                <b class="text-success">{{ userInfo.email }}</b>
              </el-form-item>
              <el-form-item label="Role">
                <template>
                  <template v-if="userInfo.roles && userInfo.roles.length > 0">
                    <span v-for="(role, index) in userInfo.roles" :key="index">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="Educator"
                        placement="top"
                      >
                        <el-tag
                          v-if="role.name === 'teacher'"
                          type="success"
                          class="mr-2 max-color"
                        >
                          <i class="fas fa-chalkboard-teacher"></i>
                        </el-tag>
                      </el-tooltip>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="Super Admin"
                        placement="top"
                      >
                        <el-tag
                          v-if="role.name === 'super_admin'"
                          type="warning"
                          class="mr-2"
                        >
                          <i class="fas fa-crown" />
                        </el-tag>
                      </el-tooltip>
                    </span>
                  </template>
                  <template v-else>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Student"
                      placement="top"
                    >
                      <el-tag type="success">
                        <i class="fas fa-user-graduate"></i>
                      </el-tag>
                    </el-tooltip>
                  </template>
                </template>
              </el-form-item>
              <el-form-item label="Country">
                <div class="showMode" v-if="userInfo.country">
                  <vue-country-intl
                    type="country"
                    v-model="userInfo.country"
                    placeholder="Select Country"
                    disabled="disabled"
                  ></vue-country-intl>
                </div>
                <span v-else>-</span>
              </el-form-item>
              <el-form-item label="Grade Level" v-if="gradeYear">
                {{ gradeYear || "-" }}
              </el-form-item>
            </el-form>
            <ProfileDialog
              ref="ProfileDialog"
              role="student"
              :userInfo="userInfo"
              @getUserProfile="getUserProfile"
            ></ProfileDialog>
          </el-tab-pane>
          <el-tab-pane label="Performance" name="performance">
            <Heading content="Performance"></Heading>
            <hr />
            <PerformanceBox
              v-if="userId && activeName === 'performance'"
              :userId="userId"
            ></PerformanceBox>
          </el-tab-pane>
          <el-tab-pane label="Plans" name="plans">
            <Heading content="Plans">
              <template slot="button" v-if="isAdmin">
                <el-button type="success" size="mini" @click="selectUser(userInfo)">
                  <i class="fas fa-edit" />
                  Edit
                </el-button>
              </template>
            </Heading>
            <hr />
            <MyPlans
              v-if="myPlans"
              :forAdmin="true"
              :myPlans="myPlans"
              :role="userRole"
              @getPlans="getUserPlans"
            ></MyPlans>
            <StudentsDialog
              ref="StudentsDialog"
              :showDialog="studentPlansShow"
              :userNow="userNow"
              :subscriptions="myPlans"
              @updateUsers="getUsers"
            ></StudentsDialog>
            <TeachersDialog
              ref="TeachersDialog"
              :showDialog="teacherPlansShow"
              :userNow="userNow"
              :subscriptions="myPlans"
              @updateUsers="getUsers"
            ></TeachersDialog>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
import { instant, user } from "@ivy-way/material";

import userApi from "@/apis/user";
import Theme from "@/common/theme";
import moment from "moment";
import MyPlans from "@/views/profile/components/MyPlans.vue";
import gradeLevelMixin from "@/mixins/gradeLevel";
import ProfileDialog from "@/views/profile/components/ProfileDialog.vue";
import PerformanceBox from "@/views/profile/components/PerformanceBox.vue";
import role from "@/mixins/role.js";

import StudentsDialog from "@/views/plans/components/StudentsDialog";
import TeachersDialog from "@/views/plans/components/TeachersDialog";

export default {
  metaInfo() {
    return {
      title: `${this.title} - ${this.CompanyName}`
    };
  },

  components: {
    MyPlans,
    ProfileDialog,
    PerformanceBox,
    StudentsDialog,
    TeachersDialog
  },

  mixins: [gradeLevelMixin, role],

  props: [],
  data() {
    return {
      moment,
      myPlans: null,
      activeName: "info",
      userInfo: null,
      userProfile: null,
      options: [],
      studentPlansShow: false,
      teacherPlansShow: false,
      userNow: null
    };
  },
  computed: {
    isAdmin() {
      return this.isRoleAdmin();
    },
    userRole(){
      let role = "student";
      if (this.userInfo) {
        let isSuperAdmin = false;
        let isTeacher = false;
        this.userInfo.roles.forEach(role => {
          if (role.name == "super_admin") {
            isSuperAdmin = true;
          }
          if (role.name == "teacher") {
            isTeacher = true;
          }
        });
        if(isTeacher){
          role = "educator";
        }
        if(isSuperAdmin){
          role = "super_admin";
        }
      }
      return role;
    },
    theme() {
      return Theme;
    },
    userId() {
      return this.$route.params.uid;
    },
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    gradeYear() {
      let text = null;
      this.options.forEach(option => {
        if (option.value == this.userInfo.graduation_year) {
          text = this.$t(option.label, { year: option.param });
        }
      });
      return text;
    },
    title() {
      if (this.userInfo) {
        return user.displayName(
          this.userInfo.first_name,
          this.userInfo.last_name
        );
      } else {
        return " - ";
      }
    }
  },
  watch: {
    userId(userId) {
      if (userId) {
        this.getUserProfile();
      }
    }
  },
  mounted() {
    if (this.$route.name === "UserProfile") {
      this.activeName = "info";
    }
    if (this.$route.query.active) {
      this.activeName = this.$route.query.active;
    }
    if (this.userId) {
      this.getUserProfile();
    }
    this.options = this.createStudentGraduateSelection();
  },
  methods: {
    getUsers(){
      this.getUserProfile();
    },
    selectUser(user){
      this.userNow = null;
      this.userNow = user;
      if (user.roles.length > 0) {
        this.selectTeacher();
      } else {
        this.selectStudent();
      }
    },
    selectStudent() {
      this.$refs.StudentsDialog.selectStudent();
    },
    selectTeacher() {
      this.$refs.TeachersDialog.selectTeacher();
    },
    editProfile() {
      this.$refs.ProfileDialog.openEditUserDialog();
    },
    editEmail() {
      this.$refs.AccountDialog.openEditUserDialog();
    },
    editPassword() {
      this.$refs.PasswordDialog.openEditUserDialog();
    },
    async getUserProfile() {
      const res = await userApi.getProfileById(this.userId);
      this.userInfo = res.user;
      this.userProfile = res.user;
      this.getUserPlans();
    },
    async getUserPlans() {
      const res = await userApi.getUserPlans(this.userId, {
        status: "active"
      });
      this.myPlans = res;
    }
  }
};
</script>

<style scoped>
.referral-code span {
  display: inline-block;
  border: 1px solid #ebeef5;
  padding: 0 20px;
  margin-right: 10px;
  font-size: 1rem;
  height: 42px;
  line-height: 42px;
}
.box-card {
  display: inline-block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.proIcon {
  display: inline-block;
  margin: 0px 2px;
  color: orange;
}
</style>
<style scoped>
::v-deep .showMode .vue-country-intl .country-intl-input-wrap {
  border: none !important;
}
::v-deep .showMode .prevent-click,
::v-deep .showMode .dropdown-flag {
  display: none;
}
::v-deep .showMode .vue-country-intl .country-intl-label {
  width: auto;
  padding: 0;
  line-height: 40px;
}
::v-deep .vue-country-disabled .country-intl-label span {
  opacity: 1;
}
</style>
